// Define re-usable constants and media queries here

// Fonts
$fontFamily: 'Hanken Grotesk';
$fontSize: 18px;

// Text
$textDefaultColor: #222222;

// Colors
$error: #960205;
$primaryGreen: #02655f;
$primaryBlue: #00a2ff;
$primaryLight: #fcfaf7;

$mint: var(--Mint, #C8E4D6);
$grey: var(--Gray-neutral-800, #1F2A37);
$cream: var(--Cream, #FCFAF7);

$orange: var(--Orange, #EE6D21);


$greyBorder: #d6d6d6;

$lightGreen: #c8e4d6;
$lightBlue: #ccecff;

// Mixins
@mixin headingStyles {
  font-size: 22px;
  font-weight: bold;
  color: #000;
}

@mixin linkStyles {
  color: #3291fb;
  cursor: pointer;
  &:hover {
    color: #1f7afb;
  }
}

