body{
        // background: #f9f7f4 ;
    .quiz-cards{
        padding-top: 80px;
    }
    .main-content{
        h1{
            color: var(--Green, #02655F);
            font-family: "Test Tiempos Headline";
            font-size: 48px;
            font-style: normal;
            font-weight: bold;
            line-height: 100%; /* 48px */
            letter-spacing: -1.44px;
            margin-bottom: 33px;
        }
        p{
            color: var(--Gray-neutral-600, #4D5761);

/* All devices/16 Paragraph M/Regular */
// font-family: Apercu;
margin: 24px 0px;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 130%; /* 20.8px */
        }
        Button{
height: 40px;
padding: 0px 20px;
gap: 8px;
border-radius: 8px;
font-size: 19px;

        }
    }

    .section-end{
      img{
        width: auto!important;
      }
    }

}
