@import '../constants.scss';


.nfp-profile{
    .preview-controls{
        min-height: 100px;
        background-color: $primaryGreen;
        p{
            // margin-top: 35px;
            font-size: 20px;
            margin: 0px;
            color: #fff;
            
        }
        .done{
            margin-left: 15px;
        }
    }
    .header-title{
        min-height: 325px;
        background-position: center;
        background-size: cover;
        position: relative;
        img{            
            position: absolute;
            bottom: -55px;
            left: 50%;
            width: 120px;
            height: 120px;
            margin-left: -60px;
        }
    }


    .nfp-profile-page{
        .title{
            min-height: 560px;
            padding-top: 95px;
            background-color: $cream;
            h1{
                text-align: center;
            }
            p{
                text-align: center;
            }
            .index-score{
                text-align: center;
                .score{
                    width: 80px;
                    margin: auto;
                    text-align: center;
                    line-height: 60px;
                    font-size: 30px;
                    font-weight: bold;
                    border-radius: 13px;
                    background-color: $mint;
                    color: $primaryGreen;
                }
                b{
                    color: $grey;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%; /* 19.2px */
                    letter-spacing: -0.32px;
                }
                a{
                    color: $primaryBlue;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%; /* 14.4px */
                    letter-spacing: -0.24px;
                }
            }
        }

        .mission{
            min-height: 400px;
            >div{
                padding-right: calc(var(--bs-gutter-x)* 2.5);
                padding-left: calc(var(--bs-gutter-x)* 2.5);
            }
            p{
                color: $grey;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%; /* 23.4px */
            }
            .icons{
                padding: 85px 125px;
                >.row:first-child{
                    margin-bottom: 30px;
                }
                .icon{
                    .svg{
                        background-color: $cream;
                        width: 80px;
                        height: 80px;
                        padding: 16px;
                        border-radius: 15px;
                    }
                    p{
                        margin: 0px;
                        padding-left: 15px;
                    }
                }
            }
        }
        .photos{
            min-height: 820px;

            >div{
                padding-right: calc(var(--bs-gutter-x)* 2.5);
                padding-left: calc(var(--bs-gutter-x)* 2.5);
            }

            .row{
                >div{
                    margin-top: 12px;
                    margin-bottom: 12px;
                }
            }


            .photo{ 
                min-height: 100px;
                background-image: url("https://cascadepublic.blob.core.windows.net/img/1719248114145.png");
                background-position: center;
                background-size: cover;
                border-radius: 12px;
                &.photo-1{
                    min-height: 250px;
                }
                &.photo-2{
                    min-height: 250px;
                }
                &.photo-3{
                    min-height: 444px;
                }
                &.photo-4{
                    min-height: 210px;
                    margin-bottom: 24px;
                }
                &.photo-5{
                    min-height: 210px;
                }
                &.photo-6{
                    min-height: 444px;
                }
            }
        }
        .projects{
            min-height: 570px;
            border-top: 1px solid $greyBorder;
            padding-top: 90px;
            >div{
                padding-right: calc(var(--bs-gutter-x)* 2.5);
                padding-left: calc(var(--bs-gutter-x)* 2.5);
            }
        }
        .reports{
            min-height: 520px;
            border-top: 1px solid $greyBorder;

            >div{
                padding-right: calc(var(--bs-gutter-x)* 2.5);
                padding-left: calc(var(--bs-gutter-x)* 2.5);
            }
        }
        .index{
            min-height: 850px;
        }
        .contact{
            min-height: 480px;
            background-color: $orange;
        }

    }
}