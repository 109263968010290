@import '../../styles/constants.scss';

/*.footer {
  position: fixed;
  bottom: 10px;
  left: 0px;
  right: 0px;
  text-align: center;
  font-size: 16px;
  border:none;
}
*/

.footer{
  display: flex;
  
  flex-direction: column;
  align-items: center;
  gap: 48px;
  background: var(--Cream-3, #F9F7F4);
  .content {

    left: 0px;
    right: 0px;
    text-align: center;
    font-size: 16px;
    border:none;
    width: 1440px;
padding: 70px 0px 56px 0px;
color: $primaryGreen;

    .col-8{
      >.row{
        padding-right: 35px;
        padding-left: 120px;
        .col-3{
          line-height: 35px;
          text-align: left;
          color: $primaryGreen;
          b{
            line-height: 40px;
          }
          a{
            
          color: $primaryGreen;
          }
        }
      }
    }

    .footer-logo{
      .logo{
        text-decoration: none;
      span{
        
        color: $primaryGreen!important;
      }
    }
    }

    .sub-footer{
      .col-3{
      text-align: right;
      font-size: 12px;
      margin-top: 70px;
      }
      
      .links{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        a{
          // margin-right: 20px;
          
          color: $primaryGreen;
        }
      }
    }

  }
}