.list-group-item{
  display: flex;
  padding: 24px 20px;
  flex-direction: column;
  align-items: flex-start;
  margin: 4px;
  border-radius: 8px;
  background: var(--Cream-3, #F9F7F4);
  
  align-items: none; 
  align-self: none;
  border: none;
  .content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    >span{
      color: var(--Gray-neutral-800, #1F2A37);
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 19.2px */
letter-spacing: -0.32px;

    }
    img{
      display: inline;
width: 32px;
height: 32px;
padding: 4.797px 5.36px 6.038px 5.468px;
justify-content: center;
align-items: center;
border-radius: 8px;
background: #DCE3E9;
border-radius: 20% !important;
    }

    .name{
      color: var(--Gray-neutral-800, #1F2A37);

      /* Desktop/18 Heading XS/Regular */
      // font-family: Apercu;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 90%; /* 16.2px */
      letter-spacing: -0.54px;
      // width: 120px;
    }
  }
}

