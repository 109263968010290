@import '../../styles/constants.scss';

.project-tile{
  img{
    max-height: 245px;
  }
  span{
    font-size: 17px;
    font-weight: bold;
    margin-top: 18px;
    color: $orange;
  }
  h2{
    margin-top: 10px;
  }
  p{
    font-size: 19px;
  }
}