@import '../../styles/constants.scss';
.sidebar-nav{
    padding-top: 10px;
    .nav-link{
        max-height: 38px;
        color: #000;
        
        background: none;
        i {
            margin-right: 10px;
            font-size: 19px;
            margin-left: 5px;
            color: #000;
        }
        &.active{
            background: $lightBlue;
        }
    }

}