@import '../../styles/constants.scss';

.button {
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  padding: 0 18px;
  transition: all 0.5s;
  -webkit-appearance: none;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  min-height: 40px;
  min-width: 100px;
  background: #7fd2ce;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__value {
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
  }

  &:focus,
  &:active,
  &:visited,
  &:hover {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    outline: none;
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;
    background: #bfbfbf;
  }


  &.full-width {
    width: 100%;
  }


  &.primary{
    border-radius: 4px;
    background: var(--Blue, #00A2FF);
    color: #ffff;
    &.green{
      background: var(--Green, #02655F);
    }

    &.white{
      background: var(--Base-White, #FFF);
      color: var(--Blue, #00A2FF);
    }

    &.grey{
      background: #9da4ae;
      color: var(--Base-White, #FFF);

    }


  }

  &.secondary{
    border-radius: 4px;
    border: 1px solid var(--Blue, #00A2FF);
    background: none;
    color: var(--Blue, #00A2FF);
    &.green{
      border: 1px solid var(--Green, #02655F);
      color: var(--Green, #02655F);
    }
    &.white{
      border: 1px solid var(--Base-White, #FFF);
      color: var(--Base-White, #FFF);
    }
    &.grey{
      border: 1px solid  #9da4ae;
      color: var(--Base-White, #9da4ae);
    }
  }

  &.sml{
    display: inline-flex;
    height: 32px;
    min-height: 32px;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    min-height: 32px;
    min-width: 70px;

    // color: var(--Base-White, #FFF);

    /* All devices/12 Paragraph XS/Bold */
    // font-family: Apercu;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 15.6px */
  }

  &.med{
    display: inline-flex;
    height: 40px;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
  }

  &.lrg{
    display: inline-flex;
    height: 54px;
    padding: 0px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
  }





}


