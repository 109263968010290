.welcome-page{
  .account-type{
    .type-select{
      padding:20px;
      background:#f9f7f4

    }

  }
}
