// .slider 
@import '../../styles/constants.scss';


.financials-form{
  #submit{
    display: none;
  }
  .year{
    margin-top: 40px;
    font-size: 24px;
    padding-left: 5px;
  }
  .checkbox{
    float: left;
    width: 120px;
    padding-left: 4px;
    label{
      font-size: 16px;
      margin-left: 9px;
      line-height: 20px;
      float: left;
      color: var(--Gray-neutral-500, #6C737F);

      /* All devices/14 Paragraph S/Bold */
      // font-family: Apercu;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 18.2px */
    }
    input{
      height: 20px;
      width: 20px;
      float: left;
    }
  }
  .upload{
    
    border-top: 1px solid #000;
    margin-top: 15px;
    padding-top: 15px;
    border-bottom: 1px solid #000;
    padding-bottom: 45px;
    
    float: left;
    color: var(--Gray-neutral-500, #6C737F);

    /* All devices/14 Paragraph S/Bold */
    // font-family: Apercu;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 18.2px */
  }
}