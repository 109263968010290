.slider-panel{
  .col-lg-6 > p{
    font-size: 25px;
    margin-top: 40px;
  }
  .fake-image{
    border-radius: 50%;
    width: 400px;
    margin-left: 80px;
    height: 400px;
  }
  button{
    margin-top: 60px;
  }
}