@import './constants.scss';

#main{
    .hero-component{
        padding-top: 25px;
        border-bottom: 1px solid $greyBorder;
        padding-bottom: 50px;
    }

    .donations{
        canvas{
            display: block;
            box-sizing: border-box;
            height: 400px;
            width: 790px;
        }
    }

}