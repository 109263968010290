.questionnaire-transparency{

  .app{
      max-width: none;
  }

  header{
      max-width: 1200px!important;
      margin: auto!important;
  }

  .header-title{
      padding-top: 70px;
      background: #f9f7f4;
      padding-bottom: 55px;
      .upper-title{
          max-width: 590px;
          margin: auto;
          font-size: 17px;
          font-weight: bold;
      }

      h1{

          max-width: 590px;
          margin: auto;

          color: var(--Green, #02655F);

  /* Desktop/64 Display M/Bold */
  font-family: "Test Tiempos Headline";
  font-size: 68px;
  font-style: normal;
  font-weight: bold;
  line-height: 100%; /* 64px */
  letter-spacing: -1.92px;
  padding-top: 5px;
  font-weight: bold;
      }
  }

  .questionnaire-transparency-page{
      max-width: 1200px!important;
      margin: auto!important;

      .slider.section{
          max-width: 600px;
          margin: auto;
          height: 1425px;

          &:has(> .slide-4.current){
              height: auto;
          }
          &:has(> .slide-0.current){
            height: 450px;
        }

        >.container{
          // background-color: #f4f4f4;
          border-radius: 15px;
          top: 0px;
          .slider-panel{
              padding: 0px;
              span{
                  font-size:18px
              }
              h2{
                  font-size: 40px;
                  margin-top: 20px;
              }
              p{
                  font-size: 16px;
              }
              img{
                width: 100%;
              }
          }
        }
      }
      .submit{
        display: none;
      }
      input[type="checkbox"] {
        appearance: auto;
      }


      .section-end {
        text-align: center;
        font-family: Arial, sans-serif;
        margin-top: 50px;
      }

      .rating-circle {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: lightgray;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        font-size: 14px;
        color: #333;
      }

      h1 {
        font-size: 24px;
        margin: 20px 0;
      }

      p {
        font-size: 14px;
        color: #555;
      }


  >.container{
      padding: 0px;
      padding-top: 35px;
  }



      // button {
      //   display: block;
      //   width: 200px;
      //   padding: 10px;
      //   margin: 10px auto;
      //   font-size: 16px;
      //   cursor: pointer;
      // }

      // .next-module {
      //   background-color: black;
      //   color: white;
      //   border: none;
      // }

      // .contact-us {
      //   background-color: white;
      //   color: black;
      //   border: 2px solid black;
      // }



    }

    label {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.26px;
      line-height: 18px;
      padding-bottom: 4px;
      padding-left: 4px;

      color: var(--Gray-neutral-500, #6C737F);

  /* All devices/14 Paragraph S/Bold */
  // font-family: Apercu;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 18.2px */

      span {
        font-size: 12px;
        padding: 5px;
      }
    }


    .checkbox{
      float: left;
      width: 120px;
      padding-left: 4px;
      label{
        font-size: 16px;
        margin-left: 9px;
        line-height: 32px;
        float: left;
        color: var(--Gray-neutral-500, #6C737F);

        /* All devices/14 Paragraph S/Bold */
        // font-family: Apercu;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 18.2px */
      }
      input{
        height: 20px;
        width: 20px;
        float: left;
      }
    }

    .upload-question{
      border-bottom: 1px solid grey;
      margin-bottom: 15px;
      .questions{
        padding-bottom: 10px;

        .checkbox{
          input{
            margin:6px
          }
          label{
            line-height: 32px;
          }
        }

        width: 65%;
        button{
          margin:0px
        }
      }
    }
  }


  .modal-dialog{
    max-width: 640px;
 .modal-content{
  h2{
    margin-top: 30px!important;
    margin-bottom: 30px!important;
  }

.modal-body{
padding: 32px;
padding-bottom: 14px;

.close-btn{
  float: right;
}
  h2 {
    text-align: center;
    max-width: 400px;
    margin: auto;
    font-weight: bold;
    color: #02655f;
  }
  input{
    margin-right: 24px;
    width: 252px;
    border-radius: 8px;
    height: 44px;
    font-size: 15px;
    color: #000;
    border: 1px solid #a4a4a4;
    padding-left: 10px;
  }
  button{
    float: right;
    margin-right: 24px;
    width: 252px;
    border-radius: 8px;
    height: 44px;
    font-size: 15px;
    color: #000;
  }
}

.modal-footer{
  button{
    margin: auto;
    width: 160px;
    font-size: 14px;
  }
}

  }

}