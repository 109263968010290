.root{
    background: url('resources/landing-v1/dash-hero-blank.png') no-repeat center center;
    background-size: auto;
    background-position-y: 0px;
    .heading{
        color: #FFF;
text-align: center;
font-family: "Test Tiempos Headline";
font-size: 63.728px;
font-style: normal;
font-weight: 500;
line-height: 97%; /* 61.816px */
letter-spacing: -1.912px;
max-width: 625px;
margin: auto;
margin-top: 75px;   
    }
    #header{
        background: none;
        .header-nav{
            .nav-link{
                color: #fff;
            }
        }
        span.d-none.d-lg-block{
            color: #FFF;
        }
    }
    
    .main{
        text-align: center;
        min-height: 700px;
        Button{
            
    margin-top: 25px;
    
    border-radius: 8px;
    background: var(--Orange, #EE6D21);
        }
    }
    .divide-line{
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    }
    svg{
        stroke:#FFF!important;
    }

}