.profile-form {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    margin: 20px;
  
    .form-step {
      display: flex;
      justify-content: space-between;
  
      .step-info {
        width: 30%;
  
        h2 {
          margin: 0;
          font-size: 24px;
        }
  
        h3 {
          margin: 10px 0;
          font-size: 20px;
        }
  
        p {
          color: #666;
        }
      }
  
      .step-content {
        width: 65%;
        display: flex;
        flex-direction: column;
  
        .cover-photo {
          display: flex;
          align-items: center;
          justify-content: space-between;
  
          .cover-photo-placeholder {
            width: 150px;
            height: 150px;
            background-color: #ddd;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
          }
  
          .profile-summary {
            text-align: center;
  
            h3 {
              margin: 10px 0;
              font-size: 24px;
            }
  
            p {
              color: #666;
            }
          }
        }
  
        .introduction {
          margin-top: 20px;
  
          label {
            display: block;
            margin-bottom: 5px;
            font-weight: bold;
          }
  
          textarea {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
          }
        }
      }
    }
  
    .form-navigation {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
  
      .next {
        padding: 10px 20px;
        border: none;
        background-color: #000;
        color: #fff;
        cursor: pointer;
      }
    }

    .profile-summary{
      h2,p{
        text-align: center;
      }
    }
  }
  