.landing-v1{
  .hero{
    min-height: 760px;
  }

  .partners{
    img{
      width: 80%;
      margin: auto;
    }
  }

  .ecosystem{
    padding-top: 110px;
    img{
      width: 75%;
      margin: auto;
    }
    padding-bottom: 110px;
  }

  .index{
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .discover{
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .reports{

  }
  .features{

  }

  .CTA{

  }





}
