@mixin animate($animation,$duration,$method,$times){
    animation: $animation $duration $method $times;
}


@mixin keyframes($name){
    @keyframes #{$name}{
        @content;
    }
}


  .section.register .container{
    position: absolute;
  }

  .credits{
    position: fixed;
    bottom: 10px;
    left: 0px;
    right: 0px;
    text-align: center;
  }

  .section{
    position: relative;
  }


  .current{
    -webkit-transform: translate3d(0,0,0);
  }

  .prev{
    -webkit-transform: translate3d(-100%,0,0);
    opacity: 0;
  }

  .next{
    -webkit-transform: translate3d(100%,0,0);
    opacity: 0;
  }


    .anim.prev{
      -webkit-animation: current-to-prev 600ms linear forwards;
      animation: current-to-prev 600ms linear forwards;
      @include keyframes(current-to-prev){
        0%{
        -webkit-transform: translateX(0);opacity: 1;
        }
       100%{
        -webkit-transform: translateX(-100%);opacity: 0;
       }
      }
      @include animate(current-to-prev, 600ms, linear, 1);
    }



    .anim.current{
      -webkit-animation: next-to-current 600ms linear forwards;
      animation: next-to-current 600ms linear forwards;
      @include keyframes(next-to-current){
        0%{
        -webkit-transform: translateX(100%);opacity: 0;
        }
       100%{
        -webkit-transform: translateX(0);opacity: 1;
       }
      }
      @include animate(next-to-current, 600ms, linear, 1);
    }


    @-webkit-keyframes next-to-current{
        from{-webkit-transform: translateX(100%);opacity: 0;}
        to{-webkit-transform: translateX(0);opacity: 1;}
    }

    @-webkit-keyframes current-to-prev{
        from{-webkit-transform: translateX(0);opacity: 1;}
        to{-webkit-transform: translateX(-100%);opacity: 0;}
    }



    .backanim.current{
      -webkit-animation: prev-to-current 600ms linear forwards;
      animation: prev-to-current 600ms linear forwards;

      @include keyframes(prev-to-current){
        0%{
        -webkit-transform: translateX(-100%);opacity: 0;
        }
       100%{
        -webkit-transform: translateX(0);opacity: 1;
       }
      }
      @include animate(prev-to-current, 600ms, linear, 1);
    }

    .backanim.next{
      -webkit-animation: current-to-next 600ms linear forwards;
      animation: current-to-next 600ms linear forwards;
      
      @include keyframes(current-to-next){
        0%{
        -webkit-transform: translateX(0);opacity: 1;
        }
       100%{
        -webkit-transform: translateX(100%);opacity: 0;
       }
      }
      @include animate(current-to-next, 600ms, linear, 1);
    }


    @-webkit-keyframes prev-to-current{
        from{-webkit-transform: translateX(-100%);opacity: 0;}
        to{-webkit-transform: translateX(0);opacity: 1;}
    }

    @-webkit-keyframes current-to-next{
        from{-webkit-transform: translateX(0);opacity: 1;}
        to{-webkit-transform: translateX(100%);opacity: 0;}
    }




    .fake-image{
          background: #333;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    display: inline-block;
    }


    .next-btn, .prev-btn{
      cursor: pointer;
    }
