@import '../../styles/constants.scss';

.image-upload {
    border: 1px dashed $greyBorder;
    border-radius: 4px;
    padding: 40px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    
    background-size: 100%;
    background-repeat: no-repeat;


    input{
      display: none;
    }
    &:hover {
      background-color: #f9f9f9;
      box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.4);
      
    .upload-prompt {
         
    }
    }

    .upload-prompt {
      color: #007bff;
      padding: 10px;
    }

    .upload-prompt p {
      margin: 5px 0;
      font-size: 14px!important;
    }

    .upload-prompt strong {
      font-size: 14px!important;
      color: $primaryBlue!important;
    }

    #fileInput {
      display: none;
    }

    &.populated{
      .upload-prompt {
        opacity: 0.2;
      }
      &:hover {
        .upload-prompt {
          opacity: 1;
          background: rgba(255, 255, 255, 0.7);
        }
      }
    }
}