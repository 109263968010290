@import '../../styles/constants.scss';

.wrapper {
  padding: 0px;
  .error-wrapper {
    position: absolute;
    right: 5px;
    align-items: center;
    display: flex;
    .input-error {
      color: white;
      display: none;
      font-size: 10px;
    }
  }
  .suffix {
    position: absolute;
    right: 10px;
  }
}
.input-wrapper {
  width: 100%;
  flex: 1;
  margin-top: 17px;

  .label-error-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
  }

  label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.26px;
    line-height: 18px;
    padding-bottom: 4px;
    padding-left: 4px;

    color: var(--Gray-neutral-500, #6C737F);

/* All devices/14 Paragraph S/Bold */
// font-family: Apercu;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 130%; /* 18.2px */

    span {
      font-size: 12px;
      padding: 5px;
    }
  }

  input ,textarea{
    height: 100%;
    width: 100%;
    border: 0;
    font-size: 14px;
    padding: 10px 14px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    &.input-text{
      border-radius: 4px;
border: 1px solid var(--Gray-neutral-300, #D2D6DB);
background: var(--Base-White, #FFF);

color: var(--Gray-neutral-500, #6C737F);

/* All devices/16 Paragraph M/Regular */
// font-family: Apercu;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 130%; /* 20.8px */


    }
    &:focus {
      /*background-color: rgba(149, 149, 173, 0.15);
      box-shadow: inset 3px 3px 5px 0 rgba(54, 54, 67, 0.2),
        inset -3px -3px 5px 0 rgba(255, 255, 255, 0.8);*/
    }
    // background-color: rgba(255, 255, 255, 0.7);
    .code-verification {
      text-align: center;
    }
  }
}
.input-error {
  color: red;
  text-align: right;
}

input::placeholder {
  font-style: italic;
}
