.components{
  > .container{
    padding: 25px;
    border: 1px solid #e4e4e4;
    margin-top: 15px;
    margin-bottom: 15px;
    >h2{
      text-decoration: underline;
    }
  }



  .slider{
    min-height: 150px;
    .slide-0{
      background:#e44;
    }
    .slide-1{
      background:#4e4;
    }
    button{
      margin-bottom:50px;
    }
  }
}