.ui.styled.accordion {
  width: 100%;
}

.ui.styled.accordion .title {
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
/*  transition: background-color 0.3s ease;*/
}

.ui.styled.accordion .content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s linear;
  padding: 0 1rem;
}

.ui.styled.accordion .content p {
  margin: 0;
  padding: 10px;
}

.ui.styled.accordion .title.active {
  
}

.ui.styled.accordion .content.active {
  max-height: 120px;
  transition: max-height 0.3s linear;
/*  margin: 10px 0px;*/
}

.ui.styled.accordion .title .icon {
  margin-right: 10px;
}

.ui.styled.accordion .title.active .icon {
  transform: rotate(180deg);
}

.dropdown.accordion-button{
    background: #fff;
    box-shadow: none;
    padding: 0px;
    font-weight: normal;
    color: #000;
    font-size: 20px;
}