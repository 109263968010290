@import '../../styles/constants.scss';

.questionnaire-overview-card{
    background: #fff;
    padding: 25px !important;
    // box-shadow: none;
    
    margin-bottom: 15px;
    padding-bottom: 10px !important;

    .card-title{
        color: $primaryGreen;
        font-family: $fontFamily;
        font-size: 24px;
        font-weight: bold;
        padding-bottom: 5px;
    }

    .card-text{
        font-size: 17px;
        line-height: 20px;
    }

    .card-head{
        text-align: right;
        Button{
            font-size: 12px;
            width: auto;
            float: right;
            padding: 4px 0px;
            min-height: 0px;
            line-height: 7px;
            height: 23px;
            &:first-child{
                margin-bottom: 7px;
            }

            &.secondary{
                min-width: 40px;
            }
            

        }
    }

    .card-body{
        text-align: left;
    }

    .progress-text{
        font-size: 13px;
    }
    .percentage-stroke{
        stroke: #04e404;
    }
    .card-button{
        float:left;
    }
    button{
        // margin: 0px;
        float: right;
    }

    .circle{
        height: 90px;
        width: 90px;
        border-radius: 50%;
        // background: #aaa;
        background-color: $lightGreen;
    }
}
