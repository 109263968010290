.questionnaire-finantials{

.app{
    max-width: none;
}

header{
    max-width: 1200px!important;
    margin: auto!important;
}

.header-title{
    padding-top: 70px;
    background: #f9f7f4;
    padding-bottom: 55px;
    .upper-title{
        max-width: 590px;
        margin: auto;
        font-size: 17px;
        font-weight: bold;
    }

    h1{

        max-width: 590px;
        margin: auto;

        color: var(--Green, #02655F);

/* Desktop/64 Display M/Bold */
font-family: "Test Tiempos Headline";
font-size: 68px;
font-style: normal;
font-weight: bold;
line-height: 100%; /* 64px */
letter-spacing: -1.92px;
padding-top: 5px;
font-weight: bold;
    }
}

.questionnaire-finantials-page{
    max-width: 1200px!important;
    margin: auto!important;

    .slider.section{
        max-width: 600px;
        margin: auto;
        height: 1425px;

        &:has(> .slide-4.current){
            height: auto;
        }
        &:has(> .slide-0.current){
          height: 450px;
      }

      >.container{
        // background-color: #f4f4f4;
        border-radius: 15px;
        top: 0px;
        .slider-panel{
            padding: 0px;
            span{
                font-size:18px
            }
            h2{
                font-size: 40px;
                margin-top: 20px;
            }
            p{
                font-size: 16px;
            }
            img{
              width: 100%;
            }
        }
      }
    }
    .submit{
      display: none;
    }
    input[type="checkbox"] {
      appearance: auto;
    }


    .section-end {
      text-align: center;
      font-family: Arial, sans-serif;
      margin-top: 50px;
    }

    .rating-circle {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: lightgray;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      font-size: 14px;
      color: #333;
    }

    h1 {
      font-size: 24px;
      margin: 20px 0;
    }

    p {
      font-size: 14px;
      color: #555;
    }


>.container{
    padding: 0px;
    padding-top: 35px;
}



    // button {
    //   display: block;
    //   width: 200px;
    //   padding: 10px;
    //   margin: 10px auto;
    //   font-size: 16px;
    //   cursor: pointer;
    // }

    // .next-module {
    //   background-color: black;
    //   color: white;
    //   border: none;
    // }

    // .contact-us {
    //   background-color: white;
    //   color: black;
    //   border: 2px solid black;
    // }



  }

}
