@import '../../styles/constants.scss';
.dash{
  .next-steps{
    position: relative;
    border-bottom: 1px solid $greyBorder;
    min-height: 163px;
    button{
      position: absolute;
      top: 23px;
      right: 15px;
      font-size: 18px;
      background-color: $primaryBlue;
      border: $primaryBlue;
    }

  }
}