.charity-index-rating {
  background: url('dash-hero-blank.png') no-repeat center center;
  background-size: cover;
  color: #004A61;
  border-radius: 15px;
  .rounded{
    max-width: 500px;
    padding-left:0px!important;
  }
}

.charity-index-rating h1 {
  font-weight: bold;
}

.charity-index-rating p {
  font-size: 1rem;
  margin: 1rem 0;
}

.charity-index-rating .btn {
  background-color: #007bff;
  border-color: #007bff;
}
