// .slider
@import '../../styles/constants.scss';


.profile-form-intro{
  #submit{
    display: none;
  }
  .year{
    margin-top: 40px;
    font-size: 24px;
    padding-left: 5px;
  }
  .checkbox{
    float: left;
    width: 120px;
    padding-left: 4px;
    label{
      font-size: 16px;
      margin-left: 9px;
      line-height: 20px;
      float: left;
      color: var(--Gray-neutral-500, #6C737F);

      /* All devices/14 Paragraph S/Bold */
      // font-family: Apercu;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 18.2px */
    }
    input{
      height: 20px;
      width: 20px;
      float: left;
    }
  }
  .upload{

    border-top: 1px solid #000;
    margin-top: 15px;
    padding-top: 15px;
    border-bottom: 1px solid #000;
    padding-bottom: 45px;

    float: left;
    color: var(--Gray-neutral-500, #6C737F);

    /* All devices/14 Paragraph S/Bold */
    // font-family: Apercu;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 18.2px */
  }




  .wrapper {
    width: 100%;
    // flex: 1;
    margin-top: 17px;

    &.logo{
      width: auto;
      margin: auto;
      margin-top: 17px;
      .image-upload{
        &.logo{
          margin: auto;
          &.populated{
            
    background-size: 50%;
    background-position: center;
          }
        }
      }
    }

    .label-error-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
    }

    label {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.26px;
      line-height: 18px;
      padding-bottom: 4px;
      padding-left: 4px;

      color: var(--Gray-neutral-500, #6C737F);

  /* All devices/14 Paragraph S/Bold */
  // font-family: Apercu;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 18.2px */

      span {
        font-size: 12px;
        padding: 5px;
      }
    }
  }

  .profile-summary{
    h2,p{
      text-align: center;
    }
  }
}
