@import '../../styles/constants.scss';

.ui.styled.accordion {
  width: 100%;
}

.ui.styled.accordion .title {
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
/*  transition: background-color 0.3s ease;*/
}

.ui.styled.accordion .content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s linear;
  padding: 0 1rem;
}

.ui.styled.accordion .content p {
  margin: 0;
  padding: 10px;
}

.ui.styled.accordion .title.active {
  
}

.ui.styled.accordion .content.active {
  max-height: 120px;
  transition: max-height 0.3s linear;
/*  margin: 10px 0px;*/
}

.ui.styled.accordion .title .icon {
  margin-right: 10px;
}

.ui.styled.accordion .title.active .icon {
  transform: rotate(180deg);
}

.dropdown.accordion-button{
    background: #fff;
    box-shadow: none;
    padding: 0px;
    font-weight: normal;
    color: #000;
    font-size: 20px;
}


.header__user{
  float: right;
  right: 0px;
  position: absolute;
  top: 23.5px;
  button{
      margin-right: 15px;
      i ,svg{
          margin-right: 10px;
          stroke:#02655F;
      }
      &:last-child{
          margin-right: 0px;
      }

      &:active, &:hover{
        background-color:   #bbd5c8!important;
        color: #000!important;
        border-color: #bbd5c8!important;
      }

      &.notifications{
          background-color: $greyBorder;
          color: #000;
          border-color: $greyBorder;
          padding: 0px;
          min-width: 45px;
          float: left;
          i,svg {
              margin-right: 0px;
              stroke:#02655F;
          }
      }

      &.user{
          min-width: 110px;
          background-color: $lightGreen;
          color: #000;
          border-color: $lightGreen;
          
      }
      &.login{
        /* All devices/16 Paragraph M/Bold */
        font-family: Apercu;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 20.8px */
        background-color: $lightGreen;
        color: #000;
        border-color: $lightGreen;

      }
      
  }
}


.dropdowm-menu{
  position: absolute;
  background: $cream;
  padding: 40px;
  width: 315px;
  filter: drop-shadow(5px 5px 10px #e4e4e4);
  right: 0px;
  top: 90px;

  h2{
    padding-bottom: 15px;
    border-bottom: 1px solid $greyBorder;
  }
  input{
    display: flex;
    width: 267px;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
  }
  .form__button{
    display: flex;
    width: 100%;
    height: 40px;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--Green, #02655F);
    color: var(--Base-White, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 18.2px */
    margin-top: 30px;
  }
}