@import '../../styles/constants.scss';

.file-upload {
    border: 1px dashed $greyBorder;
    border-radius: 4px;
    padding: 40px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .file-upload:hover {
    background-color: #f9f9f9;
  }
  
  .upload-prompt {
    color: #007bff;
  }
  
  .upload-prompt p {
    margin: 5px 0;
    font-size: 14px!important;
  }
  
  .upload-prompt strong {
    font-size: 14px!important;
    color: $primaryBlue!important;
  }
  
  #fileInput {
    display: none;
  }
  