@import '../../styles/constants.scss';

#header{
    max-width: 1200px;
    margin: auto;
}
.header{
    .logo{
        text-decoration: none;
        img{
            max-height: 32px;
        }
    }
}
.header-nav{
    .nav-link{
        font-size: 18px;
        font-weight: bold;
        color: $primaryGreen;
        text-decoration: none;
        i,svg {
            margin-right: 10px;
            
            stroke:$primaryGreen;
        }
    }
}

.header__user{
    float: right;
    right: 0px;
    position: absolute;
    button{
        margin-right: 15px;
        i ,svg{
            margin-right: 10px;
            stroke:#02655F;
        }
        &:last-child{
            margin-right: 0px;
        }

        &.notifications{
            background-color: $greyBorder;
            color: #000;
            border-color: $greyBorder;
            i,svg {
                margin-right: 0px;
                stroke:#02655F;
            }
        }

        &.user{
            min-width: 110px;
            background-color: $lightGreen;
            color: #000;
            border-color: $lightGreen;
        }
    }
}

