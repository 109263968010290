@import './constants.scss';

body {
  margin: 0;
  font-size: $fontSize;
  font-family: $fontFamily;
  color: $textDefaultColor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  margin:0px;padding:0px;overflow: hidden;
}

.heading {
  @include headingStyles()
}

a, .fake-link {
  @include linkStyles()
}

.form {
  max-width: 400px;

  &__button {
    margin-top: 20px
  }
}

.error {
  color: $error;
  font-size: 14px;
}

/*Welcome Page*/
.welcome-page{


  .welcome{
    h1{
      font-size: 120px;
      font-weight: bold;
    }
    .p{
      margin-top: 30px;
      font-size: 25px;
      margin-bottom: 65px;
    }
    button{
      min-width: 165px;
      line-height: 30px;
      border-radius: 11px;
    }
  }
}




/* main app */

.app{
  // max-width: 1200px;
  // margin: auto;
  position: relative;
}

#header{
  position: relative;
  height: 87px;
  // border-bottom: 1px solid #d6d6d6;
  padding: 0px;
  box-shadow: none;
}
#footer{
  position: relative;
}

#sidebar{
  position: absolute;
  top:88px;
  width: 165px;
  padding: 0px;
  padding-top: 65px;
  box-shadow: none;
}

#main{
  margin-top: 0px;
  // margin-left: 175px;
  padding-right: 0px;
  border-top: 1px solid #d6d6d6;
  >.container{
    padding:0px;
  }
}


/* index.scss */

/* General styles */ 
body {
//   background-color: #f8f9fa; // Light background color
//   color: #212529; // Text color
//   font-family: 'Arial', sans-serif;
font-family: Hanken Grotesk;
font-size: 23px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $fontFamily;
}

span.d-none.d-lg-block{
  font-family: $fontFamily;
  font-size: 41px;
  padding-left: 7px;
}


h1{    
  font-size: 48px;
  padding-top: 15px;
  font-weight: bold;
  color: $primaryGreen;
}

a {
  color: #007bff; // Link color
  text-decoration: none;

  &:hover {
    color: #0056b3; // Darker color on hover
    text-decoration: underline;
  }
}

@media (min-width: 1200px) {
  .header .toggle-sidebar-btn.bi-list::before{
    content: '';
    padding: 0px;
  }
  #main{
    margin-left: 175px;
  }
}

/* Dashboard styles */
.dashboard-header {
  margin-bottom: 20px;
}

.dashboard-intro {
  margin-bottom: 40px;
}

.dashboard-chart {
  margin-bottom: 40px;
}

/* Projects styles */
.project-card {
  margin-bottom: 20px;

  img {
    height: 200px;
    object-fit: cover;
  }
}

/* Reports styles */
.report-list-item {
  margin-bottom: 10px;

  h5 {
    margin: 0;
  }

  p {
    margin: 0;
    color: #6c757d; // Secondary text color
  }
}

/* Partners styles */
.partner-list-item {
  display: flex;
  align-items: center;
  padding: 10px;

  img {
    margin-right: 20px;
  }

  span {
    margin-right: 20px;

    &:last-child {
      color: #6c757d; // Secondary text color
    }
  }
}
